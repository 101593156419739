export default {
  "etc-grants-dao": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们为有前途的项目提供资金，这些项目将振兴以太坊经典生态系统。"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 1 is open 31st Jan to 30th Apr 2023."])},
    "apply-now-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获奖者"])},
    "apply-now-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://etcgrants.softr.app/projects-list-zh"])},
    "browse-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目公示"])},
    "browse-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://etcgrants.softr.app/projects-list-zh"])},
    "report-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月度运营报告"])},
    "report-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://docs.google.com/presentation/d/e/2PACX-1vR-0dQ89lbkQ4pzHRCwBwNYxJK9UrxmaSj1kpJUIcyxU8J7k04hezMm9tT8w-qSXFDVLK5sz_GaEctZ/pub?start=false&loop=false&delayms=60000&slide=id.g23bfe40e123_2_423"])},
    "key-dates-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/winners-selected-cn.png"])}
  },
  "contents-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目录"])},
  "overview": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概述"])},
    "etc-grants-dao": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])}
    },
    "egd-grants-program": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGD资助项目"])}
    }
  },
  "howToApply": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何申请"])},
    "round1": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC资助 第一轮"])}
    },
    "suggestions": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议"])}
    },
    "categories": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资助类别"])}
    }
  },
  "grantsProcess": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGD资助流程"])},
    "phases": {
      "step1": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第1步：征求项目提议"])}
      },
      "step2": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第2步：提交资助申请"])}
      },
      "step3": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第2步：评估/技术审核"])}
      },
      "step4": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第4步：资助团队批准"])}
      },
      "step5": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第5步：尽职调查和KYC/AML要求"])}
      },
      "step6": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第6步：资助进度和里程碑审核"])}
      },
      "step7": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第7步：项目启动及回顾"])}
      }
    }
  },
  "resources": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC 开发者资源"])},
    "etc-developer-resources": {
      "navheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC 开发者资源"])}
    },
    "etc-grants-dao": {
      "navheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC 社区链接"])}
    },
    "faqs": {
      "navheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])}
    }
  },
  "wechat": {
    "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Website"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan the QR code below to send a friend request and we will add you to the WeChat group"])}
  },
  "footer": {
    "etc_grand_dao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])},
    "sponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors"])}
  }
}