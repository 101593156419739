export default {
  "etc-grants-dao": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide funding for promising projects that will invigorate the Ethereum Classic ecosystem."])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round 1 is open 31st Jan to 30th Apr 2023."])},
    "apply-now-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winners"])},
    "apply-now-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://etcgrants.softr.app/projects-list-funded-en"])},
    "browse-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
    "browse-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://etcgrants.softr.app/projects-list-en"])},
    "report-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Report"])},
    "report-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://docs.google.com/presentation/d/e/2PACX-1vTWM3_MPkD3ojhu1K7DB2r8VbbfW6NTYmA8UszbryQyTKrZLxwr7bAGcT6iuithSZwlRP4jEtZbwLu8/pub?start=false&loop=false&delayms=60000&slide=id.g23bfe540a28_2_0"])},
    "key-dates-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/winners-selected-en.png"])}
  },
  "contents-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])},
  "overview": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "etc-grants-dao": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])}
    },
    "egd-grants-program": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGD Grants Program"])}
    }
  },
  "howToApply": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to Apply"])},
    "round1": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGD Grants Round 1"])}
    },
    "suggestions": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestions"])}
    },
    "categories": {
      "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant Categories"])}
    }
  },
  "grantsProcess": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGD Grants Process"])},
    "phases": {
      "step1": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1: Call for Proposals"])}
      },
      "step2": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2: Grant Application Submission"])}
      },
      "step3": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3: Evaluation/Technical review"])}
      },
      "step4": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4: Grants team approval"])}
      },
      "step5": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 5: Due diligence and KYC/AML requirements"])}
      },
      "step6": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 6: Grant progress and Milestone review"])}
      },
      "step7": {
        "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 7: Wrap-up and Retrospective"])}
      }
    }
  },
  "resources": {
    "nav-heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "etc-developer-resources": {
      "navheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Developer Resources"])}
    },
    "etc-grants-dao": {
      "navheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])}
    },
    "faqs": {
      "navheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
    }
  },
  "wechat": {
    "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Website"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan the QR code below to send a friend request and we will add you to the WeChat group"])}
  },
  "footer": {
    "etc_grand_dao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETC Grants DAO"])},
    "sponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors"])}
  }
}