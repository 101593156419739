<template>
  <router-view/>
</template>
<!-- <template>
  <Appcomponent />
</template>

<script>
import Appcomponent from "./components/Appcomponent.vue";

export default {
  name: "App",
  components: {
    Appcomponent
  }
};
</script> -->
